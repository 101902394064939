var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"disable-query-params":"","fixed-items-per-page":10,"fixed-filters":_vm.fixedFilter},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{staticClass:"inlined table",attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('router-link',{staticClass:"table-edit-link",attrs:{"to":{
                            name: _vm.routeType.USER_EDIT,
                            params: { userId: item.id },
                            query: {
                                redirectTo: _vm.$route.fullPath,
                            },
                        }}},[_c('span',{class:{
                                'active-marker': item.enabled,
                                'inactive-marker': !item.enabled,
                            }}),_vm._v(" "+_vm._s(item.lastName)+" "+_vm._s(item.firstName)),_c('br')]),_c('span',{staticClass:"small"},[_vm._v("#"+_vm._s(item.id))])],1)]}},{key:"rowActions",fn:function(ref){
                            var item = ref.item;
return [_c('edit-button',{attrs:{"to":{
                        name: _vm.routeType.USER_EDIT,
                        params: { userId: item.id },
                        query: {
                            redirectTo: _vm.$route.fullPath,
                        },
                    }}}),_c('table-button',{attrs:{"tooltip":_vm.$t('actions.delete'),"color":"blue-grey","icon":"delete","approve-color":"error"},on:{"execute":function($event){return _vm.toggleAdmin(item['@id'], true)}}})]}}],null,true)})]}}])},[_c('template',{slot:"before"},[_c('form-two-col-row',{staticClass:"align-end mb-4",scopedSlots:_vm._u([{key:"col1",fn:function(){return [_c('entity-select-input',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],ref:"adminSelect",attrs:{"label":"labels.users","name":"users","entity":_vm.selectApiClientType.USER,"query-params":{ can_be_admin: true },"autocomplete":"","data-vv-as":_vm.$t('labels.users')},model:{value:(_vm.admin),callback:function ($$v) {_vm.admin=$$v},expression:"admin"}})]},proxy:true},{key:"col2",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","disabled":!_vm.admin},on:{"click":function($event){return _vm.toggleAdmin(_vm.admin)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('labels.add_new'))+" ")],1)]},proxy:true}])})],1),_c('template',{slot:"after"},[_c('create-button',{staticClass:"inlined create-button mt-5",attrs:{"depressed":"","label":_vm.$t('labels.add_new')},on:{"create":function($event){return _vm.$router.push({
                    name: _vm.routeType.USER_CREATE,
                    query: _vm.queryParam,
                })}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }